import React from "react"
import "./Frontend.css"

function Frontend() {
	return (
		<>
			<h1>Under construction!</h1>
		</>
	)
}

export default Frontend
